@import '../../global.scss';

li {
    font-size: 20px;
    margin: 15px;
    padding: 10px;
    cursor: pointer;

    &.active {
        background-color: black;
        color: white;
    }
}
