@import '../../global.scss';

.intro {
    background-color: white;
    display: flex;

    .left {
        flex: 0.3;
        background-color: yellow;
        display: flex;
        align-items: center;
        justify-content: center;


        .iconSquare {
            // width: 70%;
            // height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            //background-color: gray;

            img {
                padding: 25px;
                width: 65px;
                height: 65px;
                overflow-y: hidden;
            }
            &:hover {
                img {
                    opacity: 0.7;
                }
            }
        }

        
    }

    .right {
        flex: 0.7;
        position: relative;

        @include mobile {
        flex-direction: column;
        align-items: center;
        }
        
        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile {
                padding-left: 10px;
                align-items: left;
            }

            h1 {
                font-size: 60px;
                margin: 10px 0;

                @include mobile {
                    font-size: 45px;
                }
            }

            h2 {
                font-size: 25px;

                @include mobile {
                    font-size: 20px;
                }
            }
        }
        a {
            position: absolute;
            bottom: 10px;
            margin-left: 5px;

            img {
                width: 30px;
                height: 25px;
            }
        }
    }
}