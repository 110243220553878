@import '../../global.scss';

.topbar {
    width: 100%;
    height: 45px;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 2;
    overflow-y: hidden;

    a {
        font-size: 35px;
        padding-left: 15px;
        color: black;
        text-decoration: none;
    }    
}