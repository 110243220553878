.App {
    height: 100vh;

    .sections {
        width: 100%;
        height: calc(100vh - 45px);
        background-color: white;
        position: relative;
        top: 45px;
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;

        > *{
            width: 100vw;
            height: calc(100vh - 45px);
            scroll-snap-align: start;
        }
    }
}
