@import '../../global.scss';

.contact {
  display: flex;

  .left {
    flex: 0.3;
    background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconSquare {
        // width: 70%;
        // height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //background-color: gray;

        img {
            padding: 25px;
            width: 65px;
            height: 65px;
            overflow-y: hidden;
        }
        &:hover {
            img {
                opacity: 0.7;
            }
        }
    }

    
}

  .right {
    flex: 0.7;
    position: relative;
    justify-content: center;
    background-color: $mainColorG;

    h1 {
      padding: 15px 15px;
      font-size: 35px;
      
      @include mobile {
        font-size: 30px;        
      }
    }

    .container {
      width: 100%;
      height: 85%;
      display: flex;
      align-items: center;
      justify-content: center;

      form {
        width: 70%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        border: 1px solid rgb(149, 155, 161);
        box-shadow: 0px 0px 15px -8px black;
        padding: 10px;

        h4 {
          padding: 10px;
          overflow-y: hidden;
          font-size: 25px;
        }

        h5 {
          text-align: center;
          font-size: 15px;
          padding: 20px;
          overflow-y: hidden;

          @include mobile {
            font-size: 13px;        
          }
        }

        // input {
        //   display: flex;
        //   width: 300px;
        //   height: 25px;
        //   font-size: 15px;
        //   margin: 5px;
        //   overflow-y: hidden;

        //   @include mobile {
        //     width: 180px;        
        //   }
        // }

        // textarea {
        //   display: flex;
        //   width: 300px;
        //   height: 150px;
        //   font-size: 15px;
        //   margin: 5px;
        //   overflow-y: hidden;
        //   resize: none;

        //   @include mobile {
        //     width: 180px;        
        //   }
        // }

        .iconSquare {
          // width: 70%;
          // height: 70%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          //background-color: gray;
  
          img {
              padding: 25px;
              width: 65px;
              height: 65px;
              overflow-y: hidden;
          }
          &:hover {
              img {
                  opacity: 0.7;
              }
          }
      }

        // button {
        //   width: 200px;
        //   height: 30px;
        //   color: whitesmoke;
        //   background-color: black;
        //   padding: 5px;
        //   border: none;
        //   cursor: pointer;

        //   @include mobile {
        //     width: 180px;        
        //   }
        // }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
