@import '../../global.scss';

.certifications {
  background-color: white;
  display: flex;

  .left {
    flex-direction: column;
    flex: 0.3;
    background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      width: 90%;
      height: 90%;
      display: flex;
      align-items: left;
      justify-content: center;
      flex-direction: column;

      h4 {
        text-align: left;
        padding-top: 30px;
        padding-bottom: 5px;
        font-size: 20px;
        margin-bottom: 8px;
        overflow-y: hidden;

        @include mobile {
          font-size: 15px;   
          margin-bottom: 10px;     
        }
    }

      p {
        text-align: left;
        font-size: 15px;
        overflow-y: hidden;
          
        @include mobile {
          padding-top: 8px;
          font-size: 13px;        
        }
      }
    }
  }

  .right {
    flex: 0.7;
    position: relative;

    h1 {
      padding: 15px 15px;
      font-size: 35px;

      @include mobile {
        font-size: 28px;        
      }
    }

    .container {
      width: 100%;
      height: 80%;
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;

      
      @include mobile {
        flex-direction: column;        
      }

      .card {
        width: 220px;
        height: 450px;
        box-shadow: 0px 0px 15px -8px black;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 20px;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.05);
        }

        .top {
            flex: 0.3;
            display: flex;
            align-items: center;
            justify-content: center;

            .institution {
                height: 70px;
                width: 70px;

                @include mobile {
                  height: 40px;
                  width: 40px;
                }
            }

        }

        .middle {
            flex: 0.3;
            align-items: center;
            justify-content: center;

            h3 {
                text-align: left;
                font-size: 15px;
                
                @include mobile {
                  font-size: 12px;
                  overflow-y: hidden;
                }
            }
            h4 {
                padding-top: 15px;
                font-size: 15px;
                color: rgb(181, 186, 192);

                @include mobile {
                  font-size: 11.5px;
                  padding-top: 0;
                  overflow-y: hidden;
                }
            }

        }

        .bottom {
            flex: 0.4;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;

            img {
                display: block;
                width: 70%;
                height: auto;
            }

            @include mobile {
              height: 80px;
              width: 90px;
            }

        }

        @include mobile {
          width: 70%;
          height: 150px;
          padding: 15px;
        }

      }

    }
    a {
      position: absolute;
      bottom: 10px;
      margin-left: 5px;

      img {
        width: 30px;
        height: 25px;
      }
    }
  }
}
