@import "../../global.scss";

.portfolio {
  background-color: gray;
  display: flex;

  .left {
    flex-direction: column;
    flex: 0.3;
    background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      width: 90%;
      height: 90%;
      display: flex;
      align-items: left;
      justify-content: center;
      flex-direction: column;

      h4 {
          text-align: left;
          padding-top: 30px;
          padding-bottom: 5px;
          font-size: 18px;
          overflow-y: hidden;

          @include mobile {
            font-size: 14px;
          }
      }

      ul {
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-y: hidden;
            list-style-type: none;
            padding-left: 0;

            li {
              margin: 0;
              width: 100%;
              text-align: left;
              font-size: 15px;
              cursor: text;
              overflow-y: hidden;
              
              @include mobile {
                font-size: 12px;
              }
              
        }
      }
    }
  }

  .right {
    flex: 0.7;
    position: relative;
    justify-content: center;
    background-color: $mainColorG;

    h1 {
      padding: 15px 15px;
      font-size: 35px;

      @include mobile {
        font-size: 30px;        
      }
    }

    ul {
      padding-top: 20px;
      margin: 10px;
      list-style: none;
      display: flex;
      justify-content: center;

      @include mobile {
        justify-content: center;
        margin: 0;
      }

    }

    .container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;

      .item {
        width: 280px;
        height: 230px;
        border: 1px solid rgb(149, 155, 161);
        box-shadow: 0px 0px 15px -8px black;
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: white;
        color: white;
        position: relative;
        transition: all 0.5s ease;

        @include mobile {
          width: 160px;
          height: 160px;
        }

        a {
          color: white;
          position: absolute;
          line-height: 35px;
          font-size: 20px;
          cursor: pointer;
          text-decoration: none;

          @include mobile {
            font-size: 15px;
          }
        }

        img {
          width: 50%;
          height: auto;
          object-fit: cover;
          z-index: 1;
        }

        &:hover {
          background-color: black;
          img {
            opacity: 0.4;
            z-index: 0;
          }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      margin-left: 5px;

      img {
          width: 30px;
          height: 25px;
      }
  }
  }
}
